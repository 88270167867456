import { Controller } from "stimulus";

const DISABLED_BG = 'tw-bg-gray-100';
const ENABLED_BG = 'tw-white';
const ENABLED_TEXT = 'tw-text-teal-900';
const DISABLED_TEXT = 'tw-text-zinc-400'; 

export default class extends Controller {
  static targets = [
    'form',
    'text',
  ]

  addCss(options) {
    this.formTargets.forEach(element => {
      element.classList.add(options.bgColor);
    })
  }

  removeCss(options) {
    this.formTargets.forEach(element => {
      element.classList.remove(options.bgColor);
    })
  }

  toggle() {
    if (this.hasFormTarget) {
      this.formTargets.forEach(element => {
        if (element.dataset.tomSelectTarget == 'select') {
          element.disabled ? element.tomselect.enable() : element.tomselect.disable()
        } else {
          if (element.disabled) {
            this.removeCss({
              bgColor: DISABLED_BG,
              textColor: DISABLED_TEXT
            });
            this.addCss({
              bgColor: ENABLED_BG,
              textColor: ENABLED_TEXT
            });
            element.disabled = !element.disabled
          } else {
            this.removeCss({
              bgColor: ENABLED_BG,
              textColor: ENABLED_TEXT
            });
            this.addCss({
              bgColor: DISABLED_BG,
              textColor: DISABLED_TEXT
            });

            element.disabled = !element.disabled
          }
        }
      })
    } else {
      console.log('no element targets')
    }
    if (this.hasTextTarget) {
      this.textTargets.forEach(element => {
        element.classList.toggle('tw-opacity-50');
      })
    }
  }

  // Private

}
